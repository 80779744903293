/**
 * @file It contains scripts for log overview page
 */
import { mapGetters, mapActions } from "vuex"
import { getListOfStrings, checkArrayEquality } from "@/utils"
import { getFootersForTable } from "@/utils/table"
import { TABLE_NAMES, STATUS_KEYS, POST_FUNCTIONS_NAMES, STRING, ENFORCE_MFA } from "@/constants"
import { FORMS, AUTOMATION_ACTIONS, AUTOMATION_TRIGGERS, WORKFLOW_UPDATE_TRANSITION_SCREEN } from "@/constants"

export default {
  name: "Logs",
  data() {
    return {
      filters               : undefined,
      search                : undefined,
      selectedDates         : [],
      showDateRangeMenu     : false,
      defaultDateRangePeriod: 90
    }
  },
  mounted() {
    this.setDefaultDates()
  },
  methods: {
    ...mapActions({
      notify  : "shared/notify",
      loadLogs: "logs/loadLogs"
    }),
    /**
      * This method is used custom filtering for vuetify data table.
      * This will be called for every cell value in the table.
      * @param {*} value is content of each cell in a the table
      */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    },
    getPreCondition(preConditionValue) {
      const [key, value] = Object.entries(preConditionValue)[0]
      if (key === "report.status") {
        return this.$t("1148")
      } else if (key === "groupId") {
        let groupNames = value.map(groupId => {
          return this.groupsMap[groupId]?.name
        })
        if (groupNames.length > 1) {
          groupNames = groupNames.join(` ${this.$t("1906")} `)
        }
        return this.$t("1903", { groupNames })
      } else if (key === "roleId") {
        let roleNames = value.map(roleId => {
          return this.rolesMap[roleId]?.name
        })
        if (roleNames.length > 1) {
          roleNames = roleNames.join(` ${this.$t("1906")} `)
        }
        return this.$t("1908", { roleNames })
      }
    },
    handleInput() {
      if (this.selectedDates.length === 2) {
        const fromDate = new Date(this.selectedDates[0])
        const toDate   = new Date(this.selectedDates[1])
        if (toDate.getTime() < fromDate.getTime()) {
          this.selectedDates = [this.selectedDates[1], this.selectedDates[0]]
        }
        this.showDateRangeMenu = false
      }
    },
    getDefaultDates() {
      const today    = new Date()
      const pastDate = new Date()
      pastDate.setDate(today.getDate() - this.defaultDateRangePeriod)
      return [
        pastDate.toISOString().split("T")[0],
        today.toISOString().split("T")[0]
      ]
    },
    setDefaultDates() {
      this.selectedDates = this.getDefaultDates()
    },
    handleEventOnBlur() {
      if (this.selectedDates.length === 0) {
        this.setDefaultDates()
      }
    },
    computeDateRangeText() {
      const formattedDates = this.selectedDates.map(date => {
        const [year, month, day] = date.split("-")
        return [day, month, year].join("-")
      })
      return formattedDates.join(" ~ ")
    }
  },
  computed: {
    ...mapGetters({
      logs                      : "logs/logs",
      translationPreferences    : "translationPreferences/translationPreferences",
      usersIncludingDeletedUsers: "users/usersIncludingDeletedUsers",
      languages                 : "languages/languages",
      loadingLogsError          : "logs/loadingLogsError",
      groups                    : "groups/groups",
      roles                     : "roles/roles"
    }),
    usersMap() {
      const usersMap = new Object()
      for (const user of this.usersIncludingDeletedUsers) {
        usersMap[user.id] = user
      }
      return usersMap
    },
    groupsMap() {
      const groupsMap = {}
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    rolesMap() {
      const rolesMap = {}
      for (const role of this.roles) {
        rolesMap[role.id] = role
      }
      return rolesMap
    },
    dateRangeText() {
      const formattedDates = this.selectedDates.map(date => {
        const dateFields = date.split("-")
        return [dateFields[2], dateFields[1], dateFields[0]].join("-")
      })
      return formattedDates.join(" ~ ")
    },
    translationPreferencesMap() {
      const translationPreferencesMap = new Object()
      for (const translationPreference of this.translationPreferences) {
        translationPreferencesMap[translationPreference.id] = translationPreference
      }
      return translationPreferencesMap
    },
    languagesMap() {
      const languagesMap = new Object()
      for (const language of this.languages) {
        languagesMap[language.id] = language
      }
      return languagesMap
    },
    logsForTable() {
      const logsForTable = new Array()
      for (const log of this.logs) {
        const user = this.usersMap[log.userId]
        let username
        let disabledButNotDeleted
        if (user) {
          username              = user.name
          disabledButNotDeleted = user.disabledButNotDeleted
        }
        const logForTable = {
          id       : log.id,
          username,
          disabledButNotDeleted,
          entityId : log.entityId,
          entity   : this.$t(this.$LOCALES.ENTITY[log.entityName]),
          createdAt: this.$moment(log.createdAt).format("D MMMM YYYY HH:mm (UTCZ)"),
          level    : this.$t(this.$LOCALES.LOG_LEVEL[log.level])
        }
        let logLocaleId   = this.$LOCALES.LOGS[log.event]
        let parameters    = new Object()
        switch (log.event) {
          case "USER_EMAIL_CHANGE":
          case "CHANNEL_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE":
          case "CHANNEL_OVERRIDE_ASK_ORGANISATION_CODE_FOR_WEB_CHANGE":
          case "CHANNEL_OVERRIDE_TRANSLATION_PREFERENCE_CHANGE":
          case "CHANNEL_ORGANISATION_CODE_CHANGE":
          case "CHANNEL_LINK_CHANGE":
          case "CHANNEL_LINK_DISPLAY_NAME_CHANGE": {
            parameters.name = log.data.otherInformation.name
            parameters.old  = log.data.initialValue || this.$t("372")
            parameters.new  = log.data.finalValue || this.$t("372")
            break
          }
          case "FIELD_CREATE": {
            parameters.fieldSystemName = log.data.otherInformation.systemName
            break
          }
          case "CHANNEL_TRANSLATION_PREFERENCE_CHANGE": {
            parameters.name = log.data.otherInformation.name
            parameters.old  = this.$t(
              this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[log.data.initialValue]?.name]
            )
            parameters.new  = this.$t(
              this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[log.data.finalValue]?.name]
            )
            break
          }
          case "DOMAIN_NAME_CHANGE":
          case "GROUP_NAME_CHANGE":
          case "CHANNEL_NAME_CHANGE":
          case "CHANNEL_DISPLAY_NAME_CHANGE":
          case "FIELD_LABEL_CHANGE":
          case "FIELD_SYSTEM_NAME_CHANGE":
          case "OPTION_LIST_NAME_CHANGE":
          case "SCREEN_NAME_CHANGE":
          case "REPLY_TEMPLATE_NAME_CHANGE":
          case "FORM_TEMPLATE_NAME_CHANGE": {
            parameters.old = log.data.initialValue || this.$t("372")
            parameters.new = log.data.finalValue
            break
          }
          case "DOMAIN_ADD_ROLE":
          case "DOMAIN_REMOVE_ROLE": {
            parameters.domainName = log.data.otherInformation.domainName,
            parameters.userOrGroupName = log.data.otherInformation.userName || log.data.otherInformation.groupName
            parameters.roleName = log.data.otherInformation.roleName
            break
          }
          case "ROLE_ADD_ACCESS":
          case "ROLE_REMOVE_ACCESS": {
            parameters.roleName        = log.data.otherInformation.roleName
            parameters.userOrGroupName = log.data.otherInformation.userName || log.data.otherInformation.groupName
            break
          }
          case "CHANNEL_CREATE": {
            parameters.name       = log.data.otherInformation.channelName
            parameters.domainName = log.data.otherInformation.domainName
            break
          }
          case "CHANNEL_ENABLE_FORM":
          case "CHANNEL_DISABLE_FORM": {
            parameters.name     = log.data.otherInformation.channelName
            parameters.formName = log.data.otherInformation.formName
            break
          }
          case "FORM_TEMPLATE_CREATE": {
            parameters.name = log.data.otherInformation.name
            if (log.data.otherInformation.reportForm === false) {
              logLocaleId = this.$LOCALES.LOGS[log.event][FORMS.ISSUE]
            } else {
              logLocaleId = this.$LOCALES.LOGS[log.event][FORMS.REPORT]
            }
            break
          }
          case "WORKFLOW_UPDATE_TRANSITION_SCREEN": {
            parameters.initialValue   = log.data.otherInformation.initialValue || this.$t("1032")
            parameters.finalValue     = log.data.otherInformation.finalValue || this.$t("1032")
            parameters.transitionName = log.data.otherInformation.transitionName
            parameters.workflowName   = log.data.otherInformation.workflowName

            if (log.data.otherInformation.initialValue === null && log.data.otherInformation.finalValue !== null) {
              parameters.screenName = log.data.otherInformation.finalValue
              logLocaleId           = this.$LOCALES.LOGS[log.event][WORKFLOW_UPDATE_TRANSITION_SCREEN.ADD_SCREEN]
            } else if (log.data.otherInformation.finalValue === null) {
              parameters.screenName = log.data.otherInformation.initialValue
              logLocaleId           = this.$LOCALES.LOGS[log.event][WORKFLOW_UPDATE_TRANSITION_SCREEN.REMOVE_SCREEN]
            } else {
              logLocaleId = this.$LOCALES.LOGS[log.event][WORKFLOW_UPDATE_TRANSITION_SCREEN.UPDATE_SCREEN]
            }
            break
          }
          case "CHANNEL_UPDATE_TRANSLATION_CONFIGURATIONS": {
            let oldValue = ""
            let newValue = ""
            for (const configuration of log.data.otherInformation.oldTranslationConfigurations) {
              oldValue += `${oldValue.length ? ", " : ""}${this.$t(configuration.enableTranslationToEnglish ? "578" : "580", {
                language: this.languagesMap[configuration.languageId]?.name
              })}`
            }

            for (const configuration of log.data.otherInformation.newTranslationConfigurations) {
              newValue += `${newValue.length ? ", " : ""}${this.$t(configuration.enableTranslationToEnglish ? "578" : "580", {
                language: this.languagesMap[configuration.languageId]?.name
              })}`
            }
            parameters.channel = log.data.otherInformation.channelName
            parameters.old     = oldValue
            parameters.new     = newValue
            break
          }
          case "CONFIGURATION_UPDATE": {
            switch (log.entityId) {
              case 1: {
                parameters.old = log.data.initialValue
                parameters.new = log.data.finalValue
                break
              }
              case 2: {
                parameters.old = this.$t(
                  this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[+log.data.initialValue]?.name]
                )
                parameters.new = this.$t(
                  this.$LOCALES.TRANSLATION_PREFERENCES[this.translationPreferencesMap[+log.data.finalValue]?.name]
                )
                break
              }
              case 7: {
                parameters.old = this.$t(
                  this.$LOCALES.SPEAKUP_ISSUE_ACKNOWLEDGEMENT[log.data.initialValue]
                )
                parameters.new = this.$t(
                  this.$LOCALES.SPEAKUP_ISSUE_ACKNOWLEDGEMENT[log.data.finalValue]
                )
                break
              }
              case 35: {
                logLocaleId    = "1974"
                parameters.old = log.data.initialValue
                parameters.new = log.data.finalValue
                break
              }
              case 37: {
                logLocaleId    = "2040"
                parameters.old = log.data.initialValue === STRING.TRUE ? ENFORCE_MFA.REQUIRED : ENFORCE_MFA.OPTIONAL
                parameters.new = log.data.finalValue === STRING.TRUE ? ENFORCE_MFA.REQUIRED : ENFORCE_MFA.OPTIONAL
                break
              }
              case 39: {
                parameters.status = log.data.finalValue === STRING.TRUE ? this.$t("2089") : this.$t("2090")
                break
              }
              case 44: {
                logLocaleId       = "2417"
                parameters.status = log.data.finalValue === STRING.TRUE ? this.$t("2418") : this.$t("2419")
                break
              }
              case 48: {
                logLocaleId       = "2601"
                parameters.status = log.data.finalValue === STRING.TRUE ? this.$t("2602") : this.$t("2603")
                break
              }

            }
            logLocaleId = this.$LOCALES.LOGS[log.event][log.data.otherInformation.key]
            break
          }
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_ADD":
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_UPDATE":
          case "DOMAIN_CHANGE_ON_SPEAK_UP_ISSUE_CREATE_AUTOMATION_RULE_REMOVE": {
            parameters.channelName = log.data.otherInformation.channelName
            parameters.domainName  = log.data.otherInformation.result.domainName
            if (log.data.otherInformation.conditions.length === 1) {
              let fieldValues             = ""
              parameters.formTemplateName = log.data.otherInformation.conditions[0].formTemplateName
              parameters.fieldSystemName  = log.data.otherInformation.conditions[0].fieldSystemName
              for (const fieldValue of log.data.otherInformation.conditions[0].fieldValues) {
                if (fieldValue === log.data.otherInformation.conditions[0].fieldValues.slice(-1)[0]){
                  fieldValues += `${fieldValue}`
                } else {
                  fieldValues += `${fieldValue}${this.$t("1120")}`
                }
              }
              parameters.fieldValues = fieldValues
            }
            break
          }
          case "DATA_RETENTION_RULE_UPDATE_SUMMARY":
          case "DATA_RETENTION_RULE_UPDATE_DESCRIPTION":
          case "DATA_RETENTION_RULE_UPDATE_ISSUE_DOCUMENTS":
          case "DATA_RETENTION_RULE_UPDATE_COMMENTS":
          case "DATA_RETENTION_RULE_UPDATE_MESSAGE_CONTENT":
          case "DATA_RETENTION_RULE_UPDATE_REPORT_DOCUMENTS":
          case "DATA_RETENTION_RULE_UPDATE_ISSUE_HISTORY": {
            parameters.old = log.data.otherInformation.initialValue
            parameters.new = log.data.otherInformation.finalValue
            break
          }
          case "WORKFLOW_NAME_CHANGE": {
            parameters.initialValue = log.data.initialValue
            parameters.finalValue   = log.data.finalValue
            break
          }
          case "ISSUE_STATUS_NAME_CHANGE": {
            parameters.initialValue = log.data.initialValue
            parameters.finalValue   = log.data.finalValue
            break
          }
          case "DATA_RETENTION_RULE_UPDATE_FIELD": {
            parameters.name = log.data.otherInformation.systemName
            parameters.old  = log.data.otherInformation.initialValue
            parameters.new  = log.data.otherInformation.finalValue
            break
          }
          case "AUTOMATION_UPDATE":
          case "AUTOMATION_CREATE": {
            parameters.name = log.data.otherInformation.name
            if (log.data.otherInformation.action === AUTOMATION_ACTIONS.DOMAIN_CHANGE.value) {
              parameters.action = `${log.data.otherInformation.action} to ${log.data.otherInformation.actionConditions.toDomain.name}`
            }
            if (log.data.otherInformation.action === AUTOMATION_ACTIONS.SEND_EMAIL_NOTIFICATION.value) {
              if (log.data.otherInformation.actionConditions.toUser) {
                parameters.action = `${log.data.otherInformation.action} to ${log.data.otherInformation.actionConditions.toUser.name}`
              } else if (log.data.otherInformation.actionConditions.toGroup) {
                parameters.action = `${log.data.otherInformation.action} to ${log.data.otherInformation.actionConditions.toGroup.name}`
              }
            }
            parameters.trigger = log.data.otherInformation.trigger
            if (log.data.otherInformation.trigger === AUTOMATION_TRIGGERS.SPEAK_UP_ISSUE_CREATE.value) {
              const domainsToBeLogged       = log.data.otherInformation.triggerConditions.domains
              const channelsToBeLogged      = log.data.otherInformation.triggerConditions.channels
              const formTemplatesToBeLogged = log.data.otherInformation.triggerConditions.formTemplates

              const automationDetailsToBeLogged = [
                { key: "1383", value: { domainNames: domainsToBeLogged.length ? domainsToBeLogged.map(domain => domain.name).join(", ") : this.$t("1384") } },
                { key: "1376", value: { channelNames: channelsToBeLogged.length ? channelsToBeLogged.map(channel => channel.name).join(", ") : this.$t("1362") } }
              ]
              if (!log.data.otherInformation.triggerConditions.reportCountries?.length) {
                automationDetailsToBeLogged.push({ key: "1377", value: { formTemplateNames: formTemplatesToBeLogged.length ? formTemplatesToBeLogged.map(formTemplate => formTemplate.name).join(", ") : this.$t("1363") } },
                  { key: "1378", value: { fieldSystemName: log.data.otherInformation.triggerConditions.field.systemName } },
                  { key: "1379", value: { optionListItemNames: log.data.otherInformation.triggerConditions.fieldValues } }
                )
              } else {
                automationDetailsToBeLogged.push({ key: "1817" },
                  { key: "1818", value: { reportCountries: log.data.otherInformation.triggerConditions.reportCountries.join(", ") } }
                )
              }
              parameters.otherInformation = automationDetailsToBeLogged.map(item=> `${this.$t(item.key, item.value)}`).join("\n")
            }
            break
          }
          case "SCREEN_ADD_SCREEN_ITEM": {
            if (log.data.otherInformation.fieldId) {
              parameters.screenItemName = log.data.otherInformation.screenItemName
            } else {
              parameters.screenItemName = this.$t(STATUS_KEYS[log.data.otherInformation.screenItemName])
            }
            parameters.screenName = log.data.otherInformation.screenName
            break
          }
          case "SCREEN_ITEM_REMOVE":
            parameters.screenItemName = STATUS_KEYS[log.data.otherInformation.screenItemName] ?
              this.$t(STATUS_KEYS[log.data.otherInformation.screenItemName]) :
              log.data.otherInformation.screenItemName
            parameters.screenName     = log.data.otherInformation.screenName
            break
          case "SCREEN_CREATE":
          case "AUTOMATION_REMOVE":
          case "AUTOMATION_EXECUTION_FAILURE": {
            parameters.name = log.data.otherInformation.name
            break
          }
          case "WORKFLOW_ADD_TRANSITION_LINK":
          case "WORKFLOW_DELETE_TRANSITION_LINK": {
            const fromStatus          = log.data.otherInformation.fromStatus
            parameters.fromStatus     = fromStatus ?? this.$t("1433")
            parameters.toStatus       = log.data.otherInformation.toStatus
            parameters.transitionName = log.data.otherInformation.transitionName
            parameters.workflowName   = log.data.otherInformation.workflowName
            break
          }
          case "WORKFLOW_ADD_PRE_CONDITION":
          case "WORKFLOW_DELETE_PRE_CONDITION": {
            const preConditionLogMessage = this.getPreCondition(log.data.otherInformation.preConditionValue)
            parameters.ruleName          = preConditionLogMessage
            parameters.transitionName    = log.data.otherInformation.transitionName
            parameters.workflowName      = log.data.otherInformation.workflowName
            break
          }
          case "WORKFLOW_DELETE_POST_FUNCTION":
          case "WORKFLOW_ADD_POST_FUNCTION": {
            const postFunctionKey   = Object.keys(log.data.otherInformation.postFunctionValue)[0]
            const postFunctionValue = Object.values(log.data.otherInformation.postFunctionValue)[0]
            const postFunctionType  = log.data.otherInformation.postFunctionType

            if (postFunctionKey) {
              const postFunction = POST_FUNCTIONS_NAMES[postFunctionType][postFunctionKey]
              if (postFunction.value === postFunctionValue) {
                parameters.ruleName       = this.$t(postFunction.label)
                parameters.transitionName = log.data.otherInformation.transitionName
                parameters.workflowName   = log.data.otherInformation.workflowName
              }
            }
            break
          }
          case "SCREEN_ITEM_UPDATE_MANDATORY": {
            parameters.screenItemName = STATUS_KEYS[log.data.otherInformation.screenItemName]
              ? this.$t(STATUS_KEYS[log.data.otherInformation.screenItemName])
              : log.data.otherInformation.screenItemName
            parameters.screenName     = log.data.otherInformation.screenName
            parameters.initialValue   = log.data.otherInformation.initialValue && !log.data.otherInformation.finalValue ? this.$t("2049") : this.$t("2050")
            parameters.finalValue     = log.data.otherInformation.initialValue && !log.data.otherInformation.finalValue ? this.$t("2050") : this.$t("2049")

            break
          }
          case "ISSUE_FORM_TEMPLATE_AUTO_ADDED_UPDATE": {
            parameters.formTemplateName = log.data.otherInformation.formTemplateName
            parameters.issueTypeName    = log.data.otherInformation.issueTypeName
            parameters.initialValue     = log.data.otherInformation.initialValue ? this.$t("2167") : this.$t("2166")
            parameters.finalValue       = log.data.otherInformation.finalValue ? this.$t("2167") : this.$t("2166")
            break
          }
          case "ISSUE_FORM_TEMPLATE_MULTI_INSTANCE_UPDATE": {
            parameters.formTemplateName = log.data.otherInformation.formTemplateName
            parameters.issueTypeName    = log.data.otherInformation.issueTypeName
            parameters.initialValue     = log.data.otherInformation.initialValue ? this.$t("2137") : this.$t("2136")
            parameters.finalValue       = log.data.otherInformation.finalValue ? this.$t("2137") : this.$t("2136")
            break
          }
          case "ROLE_SHOW_CHANGE": {
            parameters.roleName     = log.data.otherInformation.name
            parameters.initialValue = log.data.initialValue ? this.$t("2367") : this.$t("2368")
            parameters.finalValue   = log.data.finalValue ? this.$t("2367") : this.$t("2368")
            break
          }
          case "CHANNEL_ENABLE_AI_DIGITAL_AGENT":
          case "CHANNEL_DISABLE_AI_DIGITAL_AGENT": {
            parameters.name = log.data.otherInformation.name
            break
          }
          default: {
            parameters = log.data.otherInformation
          }
        }
        logForTable.message = this.$t(logLocaleId, parameters)
        logsForTable.push(logForTable)
      }
      return logsForTable
    },
    headersForTable() {
      return this.$TABLES.LOGS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.LOGS, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.logsForTable, ["id"])
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    },
    selectedDates: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue?.length === 2) {
          const queryParams = {
            createdAt: [newValue[0].concat("T00:00:00"),
              newValue[1].concat("T23:59:59")].join("to")
          }
          this.loadLogs(queryParams)
        }
      }
    },
    loadingLogsError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const defaultDates            = this.getDefaultDates()
          const shouldSetToDefaultDates = !checkArrayEquality(this.selectedDates, defaultDates)
          if (shouldSetToDefaultDates) {
            this.notify({
              type: "error",
              text: "1769"
            })
          } else {
            this.defaultDateRangePeriod -= 10
          }
          this.setDefaultDates()
        }
      }
    }
  }
}
